<template>
    <setting-layout :title="$t('pages.user.setting.creator.referrals.title')">
        <v-container>
            <div class="mb-4">
                {{
                    $t("pages.user.setting.creator.referrals.description", {
                        total: referralBonus,
                        currency: currency,
                    })
                }}
            </div>
            <h3 class="mt-10 mb-2 hint">
                {{ $t("pages.user.setting.creator.referrals.links") }}
            </h3>

            <div style="display: flex; flex-direction: column; gap: 16px">
                <v-row
                    justify="space-between"
                    v-for="(referralCode, referralCodeIndex) in referralCodes"
                    :key="referralCode.id"
                    class="referral_code_card"
                    :class="{
                        'referral_code_card--unsaved': !referralCode.id,
                        'referral_code_card--error':
                            !referralCode.utm_source && referralCodeIndex !== 0,
                    }"
                    @click="
                        referralCode.id && referralCodeIndex !== 0
                            ? !expandedReferralCodeId
                                ? (expandedReferralCodeId = referralCode.id)
                                : (expandedReferralCodeId = null)
                            : null
                    "
                >
                    <v-col
                        style="display: flex; flex-direction: column; gap: 8px"
                    >
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div style="white-space: pre-wrap">
                                <div>
                                    {{ signUpReferralCodeLink }}
                                </div>
                                <div style="color: orange">
                                    ?referral_code={{ referralCode.value }}
                                </div>
                                <div
                                    v-if="referralCode.utm_source"
                                    style="color: orange"
                                >
                                    {{
                                        "&utm_source=" + referralCode.utm_source
                                    }}
                                </div>
                                <!--                                <div-->
                                <!--                                    v-if="referralCode.utm_term"-->
                                <!--                                    style="color: lightseagreen"-->
                                <!--                                >-->
                                <!--                                    {{ "&utm_term=" + referralCode.utm_term }}-->
                                <!--                                </div>-->
                                <!--                                <div-->
                                <!--                                    v-if="referralCode.utm_campaign"-->
                                <!--                                    style="color: lightskyblue"-->
                                <!--                                >-->
                                <!--                                    {{-->
                                <!--                                        "&utm_campaign=" +-->
                                <!--                                        referralCode.utm_campaign-->
                                <!--                                    }}-->
                                <!--                                </div>-->
                                <!--                                <div-->
                                <!--                                    v-if="referralCode.utm_medium"-->
                                <!--                                    style="color: mediumpurple"-->
                                <!--                                >-->
                                <!--                                    {{-->
                                <!--                                        "&utm_medium=" + referralCode.utm_medium-->
                                <!--                                    }}-->
                                <!--                                </div>-->
                                <!--                                <div-->
                                <!--                                    v-if="referralCode.utm_content"-->
                                <!--                                    style="color: magenta"-->
                                <!--                                >-->
                                <!--                                    {{-->
                                <!--                                        "&utm_content=" +-->
                                <!--                                        referralCode.utm_content-->
                                <!--                                    }}-->
                                <!--                                </div>-->
                            </div>

                            <div
                                class="mr-2"
                                style="
                                    display: flex;
                                    flex-direction: column;
                                    gap: 16px;
                                "
                            >
                                <!-- copy -->
                                <button @click="copyLink(referralCode)">
                                    <app-icon icon="copy" size="16" />
                                </button>

                                <!-- delete -->
                                <template v-if="referralCodeIndex !== 0">
                                    <button
                                        v-if="
                                            referralCode.id &&
                                            referralCodes.filter(
                                                (item) => !!item.id
                                            ).length > 1
                                        "
                                        @click="deleteReferral(referralCode)"
                                    >
                                        <app-icon icon="delete" size="16" />
                                    </button>

                                    <button
                                        v-else
                                        @click="
                                            deleteReferralByIndex(
                                                referralCodeIndex
                                            )
                                        "
                                    >
                                        <app-icon icon="delete" size="16" />
                                    </button>
                                </template>
                            </div>
                        </div>

                        <template
                            v-if="
                                !referralCode.id ||
                                expandedReferralCodeId === referralCode.id
                            "
                        >
                            <!-- utm_source -->
                            <text-field
                                placeholder="twitter"
                                type="text"
                                class="mt-4"
                                v-model="referralCode.utm_source"
                                v-on:keyup.enter="save"
                            >
                                <template #prepend-inner>
                                    <div style="color: orange !important">
                                        utm_source:
                                    </div>
                                </template>
                            </text-field>

                            <!--                            &lt;!&ndash; utm_term &ndash;&gt;-->
                            <!--                            <text-field-->
                            <!--                                placeholder="discount+code"-->
                            <!--                                type="text"-->
                            <!--                                v-model="referralCode.utm_term"-->
                            <!--                                v-on:keyup.enter="save"-->
                            <!--                            >-->
                            <!--                                <template #prepend-inner>-->
                            <!--                                    <div-->
                            <!--                                        style="color: lightseagreen !important"-->
                            <!--                                    >-->
                            <!--                                        utm_term:-->
                            <!--                                    </div>-->
                            <!--                                </template>-->
                            <!--                            </text-field>-->

                            <!--                            &lt;!&ndash; utm_campaign &ndash;&gt;-->
                            <!--                            <text-field-->
                            <!--                                placeholder="spring_promo"-->
                            <!--                                type="text"-->
                            <!--                                v-model="referralCode.utm_campaign"-->
                            <!--                                v-on:keyup.enter="save"-->
                            <!--                            >-->
                            <!--                                <template #prepend-inner>-->
                            <!--                                    <div style="color: lightskyblue !important">-->
                            <!--                                        utm_campaign:-->
                            <!--                                    </div>-->
                            <!--                                </template>-->
                            <!--                            </text-field>-->

                            <!--                            &lt;!&ndash; utm_medium &ndash;&gt;-->
                            <!--                            <text-field-->
                            <!--                                placeholder="social"-->
                            <!--                                type="text"-->
                            <!--                                v-model="referralCode.utm_medium"-->
                            <!--                                v-on:keyup.enter="save"-->
                            <!--                            >-->
                            <!--                                <template #prepend-inner>-->
                            <!--                                    <div style="color: mediumpurple !important">-->
                            <!--                                        utm_medium:-->
                            <!--                                    </div>-->
                            <!--                                </template>-->
                            <!--                            </text-field>-->

                            <!--                            &lt;!&ndash; utm_content &ndash;&gt;-->
                            <!--                            <text-field-->
                            <!--                                placeholder="cta_button"-->
                            <!--                                type="text"-->
                            <!--                                v-model="referralCode.utm_content"-->
                            <!--                                v-on:keyup.enter="save"-->
                            <!--                            >-->
                            <!--                                <template #prepend-inner>-->
                            <!--                                    <div style="color: magenta !important">-->
                            <!--                                        utm_content:-->
                            <!--                                    </div>-->
                            <!--                                </template>-->
                            <!--                            </text-field>-->
                        </template>
                    </v-col>
                </v-row>

                <div
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 24px;
                        width: 100%;
                    "
                    class="pr-12"
                >
                    <gradient-button
                        style="width: 100%"
                        @click="save()"
                        :disable="isReferralsDisabled"
                        :style="{ 'opacity: 0.5': isReferralsDisabled }"
                    >
                        {{ $t("dialogs.save") }}
                    </gradient-button>

                    <button @click="addNewReferral()">
                        <app-icon icon="plus" size="16" />
                    </button>
                </div>
            </div>

            <h3 class="mt-10 mb-2 hint">
                {{ $t("pages.user.setting.creator.referrals.my_referrals") }}
            </h3>

            <div style="overflow-x: auto">
                <table>
                    <thead>
                        <tr>
                            <th
                                v-for="(column, i) in Object.values(columns)"
                                :key="i"
                            >
                                {{ column.label }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ref, i) in referrals" :key="i">
                            <td>
                                <router-link
                                    :to="{
                                        name: 'otherUser.profile.viewSlug',
                                        params: { slug: ref.slug },
                                    }"
                                >
                                    {{ ref.name }}
                                </router-link>
                            </td>
                            <td>{{ ref.status }}</td>
                            <td>{{ moment(ref.date).format("ll") }}</td>
                            <td style="color: orange">
                                {{ ref.utm_source }}
                            </td>
                            <!--                            <td style="color: lightseagreen">-->
                            <!--                                {{ ref.utm_term }}-->
                            <!--                            </td>-->
                            <!--                            <td style="color: lightskyblue">-->
                            <!--                                {{ ref.utm_campaign }}-->
                            <!--                            </td>-->
                            <!--                            <td style="color: mediumpurple">-->
                            <!--                                {{ ref.utm_medium }}-->
                            <!--                            </td>-->
                            <!--                            <td style="color: magenta">-->
                            <!--                                {{ ref.utm_content }}-->
                            <!--                            </td>-->
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import { mapActions, mapMutations, mapState } from "vuex";
import { SIGNUP_REFERRAL_CODE_LINK } from "@/configs/constants";
import * as clipboardy from "clipboardy";
import { CURRENCY_SYMBOL, REFERRAL_BONUS } from "@/configs/constants";
import TextField from "@/components/app/form/TextField.vue";
import GradientButton from "@/components/app/button/GradientButton.vue";

export default {
    name: "Referrals",
    data: () => ({
        currency: CURRENCY_SYMBOL,
        referralBonus: REFERRAL_BONUS,
        signUpReferralCodeLink: SIGNUP_REFERRAL_CODE_LINK,
        referralCodes: [],
        expandedReferralCodeId: null,
        columns: {
            name: {
                name: "name",
                label: "Name",
                width: 200,
            },
            status: {
                name: "status",
                label: "Status",
                width: 50,
            },
            date: {
                name: "date",
                label: "Date",
                width: 100,
            },
            utm_source: { name: "utm_source", label: "utm_source", width: 100 },
            // utm_term: { name: "utm_term", label: "utm_term", width: 100 },
            // utm_campaign: {
            //     name: "utm_campaign",
            //     label: "utm_campaign",
            //     width: 100,
            // },
            // utm_medium: { name: "utm_medium", label: "utm_medium", width: 100 },
            // utm_content: {
            //     name: "utm_content",
            //     label: "utm_content",
            //     width: 100,
            // },
        },
    }),
    computed: {
        ...mapState({
            referrals: (state) => state.user.user.referrals,
            user: (state) => state.user.user,
        }),
        isReferralsDisabled() {
            return !this.referralCodes
                .filter((item, index) => index !== 0)
                .every((referral) => referral?.utm_source?.trim()?.length > 0);
        },
    },
    methods: {
        ...mapMutations(["showSnackBar"]),
        ...mapActions(["getReferrals"]),

        copyLink(referralCode) {
            clipboardy.write(
                `${this.signUpReferralCodeLink}?referral_code=${
                    referralCode.value
                }${
                    referralCode.utm_source
                        ? "&utm_source=" + referralCode.utm_source
                        : ""
                }${
                    referralCode.utm_term
                        ? "&utm_term=" + referralCode.utm_term
                        : ""
                }${
                    referralCode.utm_campaign
                        ? "&utm_campaign=" + referralCode.utm_campaign
                        : ""
                }${
                    referralCode.utm_medium
                        ? "&utm_medium=" + referralCode.utm_medium
                        : ""
                }${
                    referralCode.utm_content
                        ? "&utm_content=" + referralCode.utm_content
                        : ""
                }`
            );
            this.showSnackBar(this.$t("dialogs.copied_clipboard"));
        },

        addNewReferral() {
            this.referralCodes.push({
                id: null,
                value: this.referralCodes[0].value,
                utm_source: null,
                utm_term: null,
                utm_campaign: null,
                utm_medium: null,
                utm_content: null,
            });
        },

        save() {
            if (!this.isReferralsDisabled) {
                axios
                    .post("/api/account/settings/referrals", {
                        referral_codes: this.referralCodes,
                    }, { hideLoader: true })
                    .then((response) => {
                        this.referralCodes = response.data;
                        this.showSnackBar(this.$t("dialogs.referral_code_saved"));
                    });
            }
        },

        deleteReferral(referralCode) {
            axios
                .delete("/api/account/settings/referrals/" + referralCode.id)
                .then((response) => {
                    this.referralCodes = response.data;
                });
        },

        deleteReferralByIndex(index) {
            this.referralCodes = this.referralCodes.filter(
                (item, i) => i !== index
            );
        },
    },
    async mounted() {
        await this.getReferrals();
        this.referralCodes = this.user.referralCodes;
    },
    components: { GradientButton, TextField, SettingLayout },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.hint-little {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.8em;
}
.hint {
    color: rgba(255, 255, 255, 0.6);
}

.referral_code_card {
    background: rgba(237, 237, 237, 0.1);
    border-radius: 8px;
    padding: 8px;

    &.referral_code_card--unsaved {
        outline: 1px solid rgba(white, 0.5);
    }

    &.referral_code_card--error {
        outline: 1px solid red;
    }
}

.gradient-button {
    margin: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    white-space: nowrap;
    text-align: left;
}

/* Styling for link */
td a {
    color: inherit;
    text-decoration: none;
}

td a:hover {
    text-decoration: underline;
}
</style>
