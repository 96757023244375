var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('setting-layout',{attrs:{"title":_vm.$t('pages.user.setting.creator.referrals.title')}},[_c('v-container',[_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.referrals.description", { total: _vm.referralBonus, currency: _vm.currency, }))+" ")]),_c('h3',{staticClass:"mt-10 mb-2 hint"},[_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.referrals.links"))+" ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"16px"}},[_vm._l((_vm.referralCodes),function(referralCode,referralCodeIndex){return _c('v-row',{key:referralCode.id,staticClass:"referral_code_card",class:{
                    'referral_code_card--unsaved': !referralCode.id,
                    'referral_code_card--error':
                        !referralCode.utm_source && referralCodeIndex !== 0,
                },attrs:{"justify":"space-between"},on:{"click":function($event){referralCode.id && referralCodeIndex !== 0
                        ? !_vm.expandedReferralCodeId
                            ? (_vm.expandedReferralCodeId = referralCode.id)
                            : (_vm.expandedReferralCodeId = null)
                        : null}}},[_c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","gap":"8px"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_c('div',[_vm._v(" "+_vm._s(_vm.signUpReferralCodeLink)+" ")]),_c('div',{staticStyle:{"color":"orange"}},[_vm._v(" ?referral_code="+_vm._s(referralCode.value)+" ")]),(referralCode.utm_source)?_c('div',{staticStyle:{"color":"orange"}},[_vm._v(" "+_vm._s("&utm_source=" + referralCode.utm_source)+" ")]):_vm._e()]),_c('div',{staticClass:"mr-2",staticStyle:{"display":"flex","flex-direction":"column","gap":"16px"}},[_c('button',{on:{"click":function($event){return _vm.copyLink(referralCode)}}},[_c('app-icon',{attrs:{"icon":"copy","size":"16"}})],1),(referralCodeIndex !== 0)?[(
                                        referralCode.id &&
                                        _vm.referralCodes.filter(
                                            function (item) { return !!item.id; }
                                        ).length > 1
                                    )?_c('button',{on:{"click":function($event){return _vm.deleteReferral(referralCode)}}},[_c('app-icon',{attrs:{"icon":"delete","size":"16"}})],1):_c('button',{on:{"click":function($event){return _vm.deleteReferralByIndex(
                                            referralCodeIndex
                                        )}}},[_c('app-icon',{attrs:{"icon":"delete","size":"16"}})],1)]:_vm._e()],2)]),(
                            !referralCode.id ||
                            _vm.expandedReferralCodeId === referralCode.id
                        )?[_c('text-field',{staticClass:"mt-4",attrs:{"placeholder":"twitter","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticStyle:{"color":"orange !important"}},[_vm._v(" utm_source: ")])]},proxy:true}],null,true),model:{value:(referralCode.utm_source),callback:function ($$v) {_vm.$set(referralCode, "utm_source", $$v)},expression:"referralCode.utm_source"}})]:_vm._e()],2)],1)}),_c('div',{staticClass:"pr-12",staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between","gap":"24px","width":"100%"}},[_c('gradient-button',{staticStyle:{"width":"100%"},style:({ 'opacity: 0.5': _vm.isReferralsDisabled }),attrs:{"disable":_vm.isReferralsDisabled},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("dialogs.save"))+" ")]),_c('button',{on:{"click":function($event){return _vm.addNewReferral()}}},[_c('app-icon',{attrs:{"icon":"plus","size":"16"}})],1)],1)],2),_c('h3',{staticClass:"mt-10 mb-2 hint"},[_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.referrals.my_referrals"))+" ")]),_c('div',{staticStyle:{"overflow-x":"auto"}},[_c('table',[_c('thead',[_c('tr',_vm._l((Object.values(_vm.columns)),function(column,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(column.label)+" ")])}),0)]),_c('tbody',_vm._l((_vm.referrals),function(ref,i){return _c('tr',{key:i},[_c('td',[_c('router-link',{attrs:{"to":{
                                    name: 'otherUser.profile.viewSlug',
                                    params: { slug: ref.slug },
                                }}},[_vm._v(" "+_vm._s(ref.name)+" ")])],1),_c('td',[_vm._v(_vm._s(ref.status))]),_c('td',[_vm._v(_vm._s(_vm.moment(ref.date).format("ll")))]),_c('td',{staticStyle:{"color":"orange"}},[_vm._v(" "+_vm._s(ref.utm_source)+" ")])])}),0)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }